/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { useEffect } from "react";
import { Flex, Box, Heading } from "rebass";
import styled from "styled-components";
import SceneHow from "../components/SceneHow";

import Layout from "../layouts/";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Process() { 
  
  const context = useThemeUI();
  const { theme } = context;
  const projectBgColor = theme?.colors?.backgroundElevation as string;
  const projectTextColor = theme?.colors?.text as string;
  
  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        if (rect.bottom > 0) {
          document.body.classList.add("hero-visible");
          document.body.classList.remove("hero-hidden");
        } else {
          document.body.classList.add("hero-hidden");
          document.body.classList.remove("hero-visible");
        }
      } else {
        document.body.classList.add("hero-hidden");
        document.body.classList.remove("hero-visible");
      }
    };
  
    const heroSection = document.getElementById("hero-section");
    if (heroSection) {
      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      document.body.classList.add("hero-hidden");
      document.body.classList.remove("hero-visible");
    }
  }, []);
   
  return (
    <Layout>
    <main>
      <Header bgColor={projectBgColor} textColor={projectTextColor} />
      <Flex
        flexWrap="wrap"
        alignItems="center"
        variant="containerPage"
      >
        <Box width={[1/1]}>
          <Heading as="h3" variant="catHeading">Human Centered Design</Heading>
          <Heading as="h1" variant="bigTitle" className="uppercase">
            Empathize <br />
            Define <br />
            Ideate <br />
            Prototype <br />
            Test &amp; Repeat <br />
            OOUX
          </Heading>
        </Box>
      </Flex> 
      <CanvasWrapper order={[0, 1]} width={[1/1]}>
        <SceneHow />
      </CanvasWrapper>   
        <Footer />
        </main>
      </Layout>
  );
}

const CanvasWrapper = styled(Box)`
  width: 100vw;
  height: 80vh;
  position: absolute;
  top: 24rem;
  @media only screen and (min-width: 52em) {
    height: 100vh;
  }
`;
